import {useAuthedLoader} from '@/scripts/route.mjs'
import {redirect, useParams, useSearchParams} from 'react-router-dom'
import {useAuth} from '@/hooks/auth.jsx'
import Main from './Main.jsx'
import MainIndex from './MainIndex/MainIndex.jsx'
import useRoutesIssueMains from './IssueMain/useRoutes.jsx'
import useRoutesCommunityNotice from './CommunityNotice/useRoutes.jsx'
import useRoutesUserOperation from './UserOperation/useRoutes.jsx'
const path = '/'

export default () => {
    const {user} = useAuth()

    const children = [
        {
            path: "",
            element: <MainIndex/>
        },
        ...useRoutesIssueMains(''),
        ...useRoutesCommunityNotice(''),
        ...useRoutesUserOperation(''),

    ]

    return {
        children,
        element: <Main/>,

        loader: useAuthedLoader(),

        path,
    }
}
