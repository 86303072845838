import CommunityNotice from "@/pages/Main/CommunityNotice/CommunityNotice.jsx";

const path = '/'

export default (parent) => {
    const path = 'communityNotice'
    const fullPath = `${parent}/${path}`

    const children = []

    return [
        {
            children,
            element: <CommunityNotice/>,
            path,
        },
    ]
}
