import {Outlet} from 'react-router-dom'
import {css} from '@emotion/react'
// import Footer from './Footer/Footer.jsx'
// import Header from './Header/Header.jsx'
import useDocTitle from "@/hooks/useDocTitle.mjs";
import React from 'react';
import {LaptopOutlined, NotificationOutlined, UserOutlined} from '@ant-design/icons';
import {Breadcrumb, Layout, Menu, theme} from 'antd';
import Header from './Header/Header.jsx'
import MainMenu from './MainMenu/MainMenu.jsx'

const cssMain = css({
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    minHeight: 0,
    background: '#f5f5f5',
    padding: 12,
})

export default function Main(props) {
    //useDocTitle('')
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    return (
        <Layout>
            <Header/>
            <Layout>
                <MainMenu/>
                <main css={cssMain}>
                    <Outlet/>
                </main>
            </Layout>
        </Layout>
    )
}
