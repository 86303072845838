import React from 'react'
import ReactDOM from 'react-dom/client'
import {Global} from '@emotion/react'
import App from './App.jsx'
import cssGlobal from './styles/global.mjs'
import '@/styles/main.css';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Global styles={[cssGlobal]} />
        <App />
    </React.StrictMode>,
)
