import React, {useEffect} from 'react';
import {Button, Col, DatePicker, Form, Modal, Row, Select, Space} from 'antd';

import useHttp from "@/hooks/useHttp.mjs";
import runAsync from "@/scripts/runAsync.mjs";
import RichTextEditor from "@/components/Form/RichTextEditor.jsx";
import dayjs from "dayjs";


const ModifyIssueComment = ({visible, setVisible, onCall, issueComment, userCustomerNameList}) => {
    const http = useHttp();
    const [form] = Form.useForm();
    // 组件初始化
    useEffect(() => {
        if (visible) {
            form.resetFields();
            init();
        }
    }, [visible]);
    const init = async () => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/issueComment/oneIssueComment`, {id: issueComment.id});
                }, {
                    error: {content: (err) => `查询问答失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });
            // data.crtTime = moment(data.crtTime);
            form.setFieldsValue({...data, crtTime: dayjs(data.crtTime)});
        } catch (err) {
            console.error(err);
        }
    }
    const doUpdate = async () => {
        try {
            const formField = await form.validateFields();
            formField.id = issueComment.id;
            console.log("原始数据" + formField.crtTime)
            formField.crtTime = dayjs(formField.crtTime).format('YYYY-MM-DD HH:mm:ss');
            console.log("修改数据" + formField.crtTime)
            formField.authorName = userCustomerNameList.find(item => item.value == formField.authorId).label;
            await runAsync(
                async () => {
                    return await http.post(`/issueComment/modifyIssueComment`, formField)
                },
                {
                    error: {content: (err) => `修改失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: true},
                }
            );

            setVisible(false);
            onCall();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Modal
                visible={visible}
                title="修改问答"
                okText="保存"
                cancelText="取消"
                onOk={doUpdate}
                width={1300}
                onCancel={() => setVisible(false)}
                footer={[
                    <Space>
                        <Button onClick={() => setVisible(false)}>取消</Button>
                        <Button type="primary" onClick={doUpdate}>保存</Button>
                    </Space>
                ]}
            >
                <Form form={form} onFinish={doUpdate} layout="vertical">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="authorId" rules={[
                                {required: true, message: '请选择作者',},]}
                                       label="作者">
                                <Select
                                    placeholder="请选择作者"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                                    options={userCustomerNameList}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="crtTime" rules={[{required: true, message: '请选择回答时间'}]}
                                       label="问答时间">
                                <DatePicker showTime={{format: 'HH:mm:ss'}} format="YYYY-MM-DD HH:mm:ss"
                                            style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="content" rules={[{required: true, message: '请输入问答内容',}]} label="内容">
                        <RichTextEditor/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ModifyIssueComment;
