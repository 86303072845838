import {Layout} from "antd";
import React from "react";
import logo from '@/assets/logo.png'
import {css} from '@emotion/react'

const cssLogo = css({
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    img: {
        width: 35,
    },
})

const cssAppName = css({
    color: '#fff',
    fontSize: 20,

    '@media (max-width: 899px)': {
        display: 'none',
    }
})

export default function Header({...props}) {
    return (
        <Layout.Header
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <a
                css={[cssLogo]}
                href="/"
                {...props}
            >
                <img
                    alt="logo"
                    src={logo}
                />

                <span css={cssAppName}>软件地图社区运营端</span>
            </a>
        </Layout.Header>
    )
}
