import {css} from '@emotion/react'

export default css({
    '*': {
        boxSizing: 'border-box',
        lineHeight: 'inherit',
    },

    ':root': {
        // scrollbarGutter: 'stable',
        height: '100vh',
    },

    body: {
        margin: 0,
        height: '100vh',
    },

    '#root': {
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        // color: TEXT_COLOR_PRIMARY,
        // fontSize: FONT_SIZE_PRIMARY,
        // lineHeight: 1,
    },

})
