import {Outlet, useNavigate} from 'react-router-dom'
import {css} from '@emotion/react'
import React from 'react';
import {LaptopOutlined, NotificationOutlined, UserOutlined} from '@ant-design/icons';
import {Breadcrumb, Layout, Menu, theme} from 'antd';
import useDocTitle from "@/hooks/useDocTitle.mjs";


const menuData = [
    {
        key: `index`,
        label: `首页`,
        path: '/'
    },
    {
        key: `communityNotice`,
        label: `公告管理`,
        path: '/communityNotice'
    },
    {
        key: `issueMain`,
        // icon: React.createElement(icon),
        label: `问答管理`,
        path: '/issueMain'
    },
    {
        key: `userOperation`,
        label: `运营用户`,
        path: '/userOperation'
    },
]

export default function MainMenu(props) {
    //useDocTitle('')
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const navigate = useNavigate();

    const handleMenuClick = ({item, key, keyPath, domEvent}) => {
        console.log(item)
        console.log(key)
        console.log(keyPath)
        console.log(domEvent)
        const menu = menuData.find(m => m.key === key)
        if (menu) {
            navigate(menu.path);
        }
    }
    return (
        <Layout.Sider
            width={200}
            style={{
                background: colorBgContainer,
            }}
        >
            <Menu
                mode="inline"
                // defaultSelectedKeys={['1']}
                // defaultOpenKeys={['sub1']}
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
                items={menuData}
                onClick={handleMenuClick}
            />
        </Layout.Sider>
    )
}
