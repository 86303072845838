import {useSearchParams} from "react-router-dom";
import useDocTitle from "@/hooks/useDocTitle.mjs";
import useHttp from "@/hooks/useHttp.mjs";
import {AutoComplete, Button, Col, Form, Input, Modal, Row, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import runAsync from "@/scripts/runAsync.mjs";
import FrameLayout from "@/pages/Main/components/FrameLayout.jsx";
import {ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import AddIssueComment from "@/pages/Main/IssueMain/IssueComment/AddIssueComment.jsx";
import ModifyIssueComment from "@/pages/Main/IssueMain/IssueComment/ModifyIssueComment.jsx";
import Table from "@/pages/Main/components/Table.jsx";

export default function IssueComment() {
    useDocTitle('回答管理')
    const http = useHttp()
    const [searchParams] = useSearchParams()
    const issueMainId = searchParams.get("issueMainId")

    // 表单
    const [form] = Form.useForm();
    const [blockForm] = Form.useForm();
    // 数据模型
    const [issueComments, setIssueMains] = useState([]);
    const [issueComment, setIssueMain] = useState([]);
    const [userCustomerNameList, setUserCustomerNameList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    //分页用数据
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    //页面初始化
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                await Promise.all([init(), doSearch()])
            } finally {
                setLoading(false)
            }
        })()
    }, [currentPage, pageSize]);
    //初始化用到的数据
    const init = async () => {
        try {
            const userRandomNameData = await runAsync(
                async () => {
                    return await http.get(`/userCustomer/nameList`, {});
                }, {
                    error: {content: (err) => `查询用户昵称失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });
            const options = userRandomNameData.map(data => ({value: data.id, label: data.nickname}));
            setUserCustomerNameList(options);
        } catch (err) {
            console.error(err);
        }
    }
    //查询
    const doSearch = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.pageNumber = currentPage;
            formField.pageSize = pageSize;
            formField.issueMainId = issueMainId;
            const data = await runAsync(
                async () => {
                    return await http.get(`/issueComment/pagingIssueComment`, formField)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setIssueMains(data);
            setCurrentPage(data.pageNumber);
            setPageSize(data.pageSize);
            setTotalItems(data.totalRecord);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 清空查询条件，刷新列表
    const doReset = () => {
        setCurrentPage(1);
        form.resetFields();
        doSearch();
    }


    // 处理页码改变
    const handlePageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setPageSize(newPageSize);
    };

    //获取当前数据
    const onEdit = (record) => {
        setVisibleEdit(true);
        setIssueMain(record);
    }

    const handleSearch = (value) => {
        if (value) {
            const filteredOptions = userCustomerNameList.filter(option =>
                option.label.toLowerCase().includes(value.toLowerCase())
            );
            const modifiedOptions = filteredOptions.map(option => ({
                value: option.label,
                label: option.label
            }));
            setDataSource(modifiedOptions);
        } else {
            setDataSource([]);
        }
    };

    //删除当前数据
    const doDelete = (record) => {
        Modal.confirm({
            title: '操作提示',
            okText: '确认',
            cancelText: '取消',
            content: '确认删除该回答？',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            onOk: async () => {
                try {
                    await runAsync(
                        async () => {
                            return await http.delete('/issueComment/deleteIssueComment', record)
                        },
                        {
                            error: {content: (err) => `修改失败：${err.message}`},
                            loading: {show: true},
                            success: {show: true},
                        }
                    );
                    doSearch();
                } catch (err) {
                    console.log(err);
                }
            }
        });
    }

    //修改当前数据采纳状态
    const modifyAcceptState = (record) => {
        let tip = '确认修改状态？';
        if (record.acceptState) {
            tip = "是否取消采纳该回答？";
        } else {
            tip = "是否采纳该回答?";
        }
        Modal.confirm({
            title: '操作提示',
            okText: '确认',
            cancelText: '取消',
            content: tip,
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            onOk: async () => {
                try {
                    await runAsync(
                        async () => {
                            return await http.post('/issueComment/modifyIssueCommentAcceptState', record)
                        },
                        {
                            error: {content: (err) => `修改失败：${err.message}`},
                            loading: {show: true},
                            success: {show: true},
                        }
                    );
                    doSearch();
                } catch (err) {
                    console.log(err);
                }
            }
        });
    }

    //修改当前数据冻结状态
    const modifyBlockFlag = (record) => {
        let tip = '确认修改状态？';
        let content = null;

        if (record.blockFlag) {
            tip = "是否取消冻结该回答？";
        } else {
            tip = "是否冻结该回答?";
            content = (
                <Form form={blockForm}>
                    <Form.Item name="blockReason">
                        <Input
                            placeholder="请输入冻结原因"
                        />
                    </Form.Item>
                </Form>
            );
        }

        Modal.confirm({
            title: '操作提示',
            okText: '确认',
            cancelText: '取消',
            content: (
                <div>
                    <p>{tip}</p>
                    {content}
                </div>
            ),
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            onOk: async () => {
                try {
                    const blockFormField = await blockForm.validateFields();
                    const modifiedRecord = record.blockFlag ? record : {
                        ...record,
                        blockReason: blockFormField.blockReason
                    };
                    await runAsync(
                        async () => {
                            return await http.post('/issueComment/modifyIssueCommentBlockFlag', modifiedRecord);
                        },
                        {
                            error: {content: (err) => `修改失败：${err.message}`},
                            loading: {show: true},
                            success: {show: true},
                        }
                    );
                    blockForm.resetFields();
                    doSearch();
                } catch (err) {
                    console.log(err);
                }
            },
        });
    }

    const ICommentTable = () => {
        const columns = [
            {
                title: '作者',
                dataIndex: 'authorName',
            },
            {
                title: '是否采纳为答案',
                dataIndex: 'acceptState',
                render: (text, record) => {
                    if (text) {
                        return ('是');
                    } else {
                        return ('否');
                    }
                }

            },
            {
                title: '是否冻结',
                dataIndex: 'blockFlag',
                render: (text, record) => {
                    if (text) {
                        return ('是');
                    } else {
                        return ('否');
                    }
                }

            },
            {
                title: '创建人',
                dataIndex: 'crtName'

            },
            {
                title: '创建时间',
                dataIndex: 'crtTime',

            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => {
                    return (<>
                            <a onClick={() => onEdit(record)}>修改 </a>
                            <a onClick={() => doDelete(record)}>删除 </a>
                            <a onClick={() => modifyAcceptState(record)}> {record.acceptState ? '取消采纳 ' : '设为采纳 '}</a>
                            <a onClick={() => modifyBlockFlag(record)}> {record.blockFlag ? '取消冻结 ' : '设为冻结 '}</a>
                        </>
                    );
                }
            },
        ];

        return <Table columns={columns}
                      size="small"
                      loading={loading}
                      dataSource={issueComments.data}
                      rowKey={record => record.id}
                      pagination={{
                          showSizeChanger: true,
                          current: currentPage,
                          pageSize: pageSize,
                          total: totalItems,
                          showTotal: (total, range) => (
                              <div>
                                  <span>总共 {total} 条数据</span>
                                  {/*<span>当前范围: {range[0]}-{range[1]}</span>*/}
                              </div>
                          ),
                          onChange: handlePageChange,
                      }}
        />;
    }

    return (
        <>
            <FrameLayout>
                <FrameLayout.Header title="回答管理"/>
                <FrameLayout.Content>
                    {/*查询条件*/}
                    <div style={{padding: '24px 24px 0', background: '#fff'}}>
                        <Form form={form} onFinish={doSearch} className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={5}>
                                    <Form.Item name="authorName" label="作者">
                                        <AutoComplete
                                            options={dataSource}
                                            onSearch={handleSearch}
                                            placeholder="请输入作者"
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name="acceptState" label="是否采纳为答案">
                                        <Select
                                            placeholder="请选择"
                                            options={[
                                                {value: false, label: '否'},
                                                {value: true, label: '是'},
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5} offset={9} style={{textAlign: 'right'}}>
                                    <Button
                                        onClick={doReset}
                                        style={{margin: '0 12px'}}>重置</Button>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>查询</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="smstable smstable-margin">
                        <div className="smstable-body">
                            {/* 按钮区 */}
                            <div className="smstable-body-toolbar">
                                <Space>
                                    <AddIssueComment userCustomerNameList={userCustomerNameList}
                                                     issueMainId={issueMainId}
                                                     onRefresh={doSearch}/>
                                </Space>
                            </div>
                            {/*数据区*/}
                            <ICommentTable/>
                        </div>
                    </div>
                </FrameLayout.Content>
                <ModifyIssueComment visible={visibleEdit} setVisible={setVisibleEdit}
                                    onCall={doSearch} userCustomerNameList={userCustomerNameList}
                                    issueComment={issueComment}
                />
            </FrameLayout>
        </>
    )
}
