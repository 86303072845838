import Login from './Login.jsx'
import {redirect} from 'react-router-dom'
import {useAuth} from '@/hooks/auth.jsx'

export default () => {
    const {user} = useAuth()

    const loader = ({request: {url}}) => {
        if (user) {
            const {searchParams} = new URL(url)
            const r = searchParams.get('r')
            return redirect(r ?? '/')
        }
        else {
            return null
        }
    }
    return {
        element: <Login />,
        path: '/login',
        loader,
    }
}
