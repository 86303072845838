import { Space } from 'antd';

// 布局画布
const FrameLayout = (props) => {
    const cssLayout = {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        overflow: 'hidden',
    }

    return (
        <section
            css={cssLayout}
            {...props}
        />
    );
}

// frame header
FrameLayout.Header = (props) => {
    const cssHeader = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: '0',
        padding: '12px 24px',
        background: '#fff',
        overflow: 'hidden',
    }

    const cssTitle = {
        fontSize: 20,
        fontWeight: 'bold',
    }

    const cssButtons = {
    }

    return (
        <header
            css={cssHeader}
            {...props}
        >
            <div css={cssTitle}>{props.title}</div>
            <Space css={cssButtons}>{props.children}</Space>
        </header>
    );
}

// 内容区背景色为灰色 #f0f2f5
FrameLayout.Content = (props) => {
    const cssContent = {
        padding: '12px',
        overflow: 'auto',
    }

    return (
        <main
            css={cssContent}
            {...props}
        />
    );
}

export default FrameLayout;
