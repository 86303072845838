import {AutoComplete, Button, Col, Form, Input, Modal, Row, Space} from 'antd'
import React, {useEffect, useState} from "react";
import runAsync from "@/scripts/runAsync.mjs";
import useHttp from "@/hooks/useHttp.mjs";
import useDocTitle from "@/hooks/useDocTitle.mjs";
import FrameLayout from "@/pages/Main/components/FrameLayout.jsx";
import {ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import Table from "@/pages/Main/components/Table.jsx";
import ModifyCommunityNotice from "@/pages/Main/CommunityNotice/ModifyCommunityNotice.jsx";
import AddCommunityNotice from "@/pages/Main/CommunityNotice/AddCommunityNotice.jsx";

export default function CommunityNotice() {
    useDocTitle('公告管理')
    const http = useHttp()
    // 表单
    const [form] = Form.useForm();

    // 数据模型
    const [communityNotices, setCommunityNotices] = useState([]);
    const [communityNotice, setCommunityNotice] = useState([]);
    const [userCustomerNameList, setUserCustomerNameList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    //分页用数据
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    //页面初始化
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                await Promise.all([init(), doSearch()])
            } finally {
                setLoading(false)
            }
        })()
    }, [currentPage, pageSize]);

    //初始化用到的数据
    const init = async () => {
        try {
            const userRandomNameData = await runAsync(
                async () => {
                    return await http.get(`/userCustomer/nameList`, {adminFlag: true});
                }, {
                    error: {content: (err) => `查询用户昵称失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });
            const options = userRandomNameData.map(data => ({value: data.id, label: data.nickname}));
            setUserCustomerNameList(options);
        } catch (err) {
            console.error(err);
        }
    }
    //查询
    const doSearch = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.pageNumber = currentPage;
            formField.pageSize = pageSize;
            const data = await runAsync(
                async () => {
                    return await http.get(`/communityNotice/pagingCommunityNotice`, formField)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setCommunityNotices(data);
            setCurrentPage(data.pageNumber);
            setPageSize(data.pageSize);
            setTotalItems(data.totalRecord);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 清空查询条件，刷新列表
    const doReset = () => {
        setCurrentPage(1);
        form.resetFields();
        doSearch();
    }


    // 处理页码改变
    const handlePageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setPageSize(newPageSize);
    };


    const doModify = (record) => {
        let tip = '确认修改状态？';
        if (record.state === 1) {
            tip = "是否发布公告？";
        } else {
            tip = "是否撤回公告?";
        }
        Modal.confirm({
            title: '操作提示',
            okText: '确认',
            cancelText: '取消',
            content: tip,
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            onOk: async () => {
                try {
                    await runAsync(
                        async () => {
                            return await http.post('/communityNotice/modifyCommunityNoticeState', record)
                        },
                        {
                            error: {content: (err) => `修改失败：${err.message}`},
                            loading: {show: true},
                            success: {show: true},
                        }
                    );
                    doSearch();
                } catch (err) {
                    console.log(err);
                }
            }
        });
    }


    //获取当前数据
    const onEdit = (record) => {
        setVisibleEdit(true);
        setCommunityNotice(record);
    }
    const handleSearch = (value) => {
        if (value) {
            const filteredOptions = userCustomerNameList.filter(option =>
                option.label.toLowerCase().includes(value.toLowerCase())
            );

            const modifiedOptions = filteredOptions.map(option => ({
                value: option.label,
                label: option.label
            }));

            setDataSource(modifiedOptions);
        } else {
            setDataSource([]);
        }
    };
    const IMainTable = () => {
        const columns = [
            {
                title: '标题',
                dataIndex: 'title',
            },
            {
                title: '状态',
                dataIndex: 'state',
                render: (text, record) => {
                    if (text == 0) {
                        return ('正常/发布');
                    } else if (text == 1) {
                        return ('撤回');
                    } else {
                        return ('未知状态');
                    }
                }

            },
            {
                title: '作者',
                dataIndex: 'authorName',
            },
            {
                title: '创建人',
                dataIndex: 'crtName'

            },
            {
                title: '创建时间',
                dataIndex: 'crtTime',

            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => {
                    return (<>
                            <a onClick={() => onEdit(record)}>修改 </a>
                            <a onClick={() => doModify(record)}> {record.state === 1 ? '发布' : '撤回'}</a>
                        </>)
                }
            }
        ];

        return <Table
            size="small"
            loading={loading}
            dataSource={communityNotices.data}
            rowKey={record => record.id}
            pagination={{
                showSizeChanger: true,
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showTotal: (total, range) => (
                    <div>
                        <span>总共 {total} 条数据</span>
                    </div>
                ),
                onChange: handlePageChange,
            }}
            columns={columns}/>;
    }
    return (
        <>
            <FrameLayout>
                <FrameLayout.Header title="公告管理"/>
                <FrameLayout.Content>
                    {/*查询条件*/}
                    <div style={{padding: '24px 24px 0', background: '#fff'}}>
                        <Form
                            form={form} onFinish={doSearch}
                            className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item name="title" label="标题">
                                        <Input size="middle" placeholder="请输入公告标题" allowClear/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name="authorName" label="作者">
                                        <AutoComplete
                                            options={dataSource}
                                            onSearch={handleSearch}
                                            placeholder="请输入作者"
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={5} offset={9} style={{textAlign: 'right'}}>
                                    <Button
                                        onClick={doReset}
                                        style={{margin: '0 12px'}}>重置</Button>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>查询</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="smstable smstable-margin">
                        <div className="smstable-body">
                            {/* 按钮区 */}
                            <div className="smstable-body-toolbar">
                                <Space>
                                    <AddCommunityNotice onRefresh={doSearch}
                                                        userCustomerNameList={userCustomerNameList}/>
                                </Space>
                            </div>
                            {/*数据区*/}
                            <IMainTable/>
                        </div>
                    </div>
                </FrameLayout.Content>
                <ModifyCommunityNotice visible={visibleEdit} setVisible={setVisibleEdit}
                                       onCall={doSearch} userCustomerNameList={userCustomerNameList}
                                       communityNotice={communityNotice}
                />
            </FrameLayout>
        </>
    )
}
