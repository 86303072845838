import UserOperation from "@/pages/Main/UserOperation/UserOperation.jsx";

const path = '/'

export default (parent) => {
    const path = 'userOperation'
    const fullPath = `${parent}/${path}`

    const children = []

    return [
        {
            children,
            element: <UserOperation/>,
            path,
        },
    ]
}
