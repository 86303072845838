import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Select, Space} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import useHttp from "@/hooks/useHttp.mjs";
import runAsync from "@/scripts/runAsync.mjs";
import RichTextEditor from "@/components/Form/RichTextEditor.jsx";
// import runAsync from "@/script/runAsync.mjs";

const AddUserOperation = ({onRefresh}) => {
    const http = useHttp();
    const [form] = Form.useForm();

    // 加载显示
    const [visible, setVisible] = useState(false);


    // 组件初始化
    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);
    const doAdd = async () => {
        try {
            const formField = await form.validateFields();
            await runAsync(
                async () => {
                    return await http.post(`/userOperation/addUserOperation`, formField);
                }, {
                    error: {content: (err) => `新增失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: true},
                });
            setVisible(false);
            onRefresh();
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <>
            <Button type="primary" icon={<PlusCircleOutlined/>} onClick={() => setVisible(true)}>新建管理用户</Button>

            <Modal
                visible={visible}
                title="新建管理用户"
                okText="保存"
                cancelText="取消"
                onCancel={() => setVisible(false)}
                footer={[
                    <Space>
                        <Button onClick={() => setVisible(false)}>取消</Button>
                        <Button type="primary" onClick={doAdd}>保存</Button>
                    </Space>
                ]}
            >
                <Form form={form} onFinish={doAdd} layout="vertical">
                    <Form.Item name="username" rules={[
                        {required: true, message: '请输入用户名',}, {max: 50, message: '最长50位',},]}
                               label="用户名">
                        <Input size="middle" placeholder="请输入用户名"/>
                    </Form.Item>
                    <Form.Item name="nickname" label="昵称">
                        <Input size="middle" placeholder="请输入昵称"/>
                    </Form.Item>
                    <Form.Item name="pwd" rules={[
                        {required: true, message: '请输入密码',}, {max: 50, message: '最长50位',},]}
                               label="密码">
                        <Input.Password size="middle" placeholder="请输入密码"/>
                    </Form.Item>
                </Form>
            </Modal>

        </>
    );
}

export default AddUserOperation;
