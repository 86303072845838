import React, {useEffect} from 'react';
import {Button, Col, Form, Input, Modal, Radio, Row, Select, Space} from 'antd';

import useHttp from "@/hooks/useHttp.mjs";
import runAsync from "@/scripts/runAsync.mjs";
import RichTextEditor from "@/components/Form/RichTextEditor.jsx";
import dayjs from "dayjs";


const ModifyUserOperation = ({visible, setVisible, onCall, userOperation}) => {
    const http = useHttp();
    const [form] = Form.useForm();
    // 组件初始化
    useEffect(() => {
        if (visible) {
            form.resetFields();
            form.setFieldsValue({...userOperation});
        }
    }, [visible]);
    const doUpdate = async () => {
        try {
            const formField = await form.validateFields();
            formField.id = userOperation.id;
            await runAsync(
                async () => {
                    return await http.post(`/userOperation/modifyUserOperation`, formField)
                },
                {
                    error: {content: (err) => `修改失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: true},
                }
            );
            setVisible(false);
            onCall();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Modal
                visible={visible}
                title="修改管理用户"
                okText="保存"
                cancelText="取消"
                onOk={doUpdate}
                onCancel={() => setVisible(false)}
                footer={[
                    <Space>
                        <Button onClick={() => setVisible(false)}>取消</Button>
                        <Button type="primary" onClick={doUpdate}>保存</Button>
                    </Space>
                ]}
            >
                <Form form={form} onFinish={doUpdate} layout="vertical">
                    <Form.Item name="username" rules={[
                        {required: true, message: '请输入用户名',}, {max: 50, message: '最长50位',},]}
                               label="用户名">
                        <Input size="middle" placeholder="请输入用户名"/>
                    </Form.Item>
                    <Form.Item name="nickname" label="昵称">
                        <Input size="middle" placeholder="请输入昵称"/>
                    </Form.Item>
                    <Form.Item name="pwd" rules={[{max: 50, message: '最长50位',},]}
                               label="密码">
                        <Input.Password size="middle" placeholder="请输入密码"/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ModifyUserOperation;
