import IssueMain from './IssueMain.jsx'
import IssueComment from "./IssueComment/IssueComment.jsx";

const path = '/'

export default (parent) => {
    const path = 'issueMain'
    const fullPath = `${parent}/${path}`

    const children = []

    return [
        {
            children,
            element: <IssueMain/>,
            path,
        },
        {
            element: <IssueComment/>,
            path: `${path}/issueComment`,
        }
    ]
}
