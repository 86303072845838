import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import useRoutesLogin from '@/pages/Login/useRoutes.jsx'
import useRoutesMain from '@/pages/Main/useRoutes.jsx'

export default function Router() {
    const router = createBrowserRouter([
        useRoutesLogin(),
        useRoutesMain(),
    ])

    return <RouterProvider router={router} />
}
