import {Button, Col, Form, Input, Modal, Row, Space} from 'antd'
import React, {useEffect, useState} from "react";
import runAsync from "@/scripts/runAsync.mjs";
import useHttp from "@/hooks/useHttp.mjs";
import useDocTitle from "@/hooks/useDocTitle.mjs";
import FrameLayout from "@/pages/Main/components/FrameLayout.jsx";
import {ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import Table from "@/pages/Main/components/Table.jsx";
import ModifyUserOperation from "@/pages/Main/UserOperation/ModifyUserOperation.jsx";
import AddUserOperation from "@/pages/Main/UserOperation/AddUserOperation.jsx";

export default function UserOperation() {
    useDocTitle('运营用户管理')
    const http = useHttp()
    // 表单
    const [form] = Form.useForm();

    // 数据模型
    const [userOperations, setUserOperations] = useState([]);
    const [userOperation, setUserOperation] = useState([]);
    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    //分页用数据
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    //页面初始化
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                await Promise.all([doSearch()])
            } finally {
                setLoading(false)
            }
        })()
    }, [currentPage, pageSize]);

    //查询
    const doSearch = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.pageNumber = currentPage;
            formField.pageSize = pageSize;
            const data = await runAsync(
                async () => {
                    return await http.get(`/userOperation/pagingUserOperation`, formField)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setUserOperations(data);
            setCurrentPage(data.pageNumber);
            setPageSize(data.pageSize);
            setTotalItems(data.totalRecord);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 清空查询条件，刷新列表
    const doReset = () => {
        setCurrentPage(1);
        form.resetFields();
        doSearch();
    }


    // 处理页码改变
    const handlePageChange = (newPage, newPageSize) => {
        setCurrentPage(newPage);
        setPageSize(newPageSize);
    };


    //获取当前数据
    const onEdit = (record) => {
        setVisibleEdit(true);
        setUserOperation(record);
    }

    //删除当前数据
    const doDelete = (record) => {
        Modal.confirm({
            title: '操作提示',
            okText: '确认',
            cancelText: '取消',
            content: '确认删除该运营用户？',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            onOk: async () => {
                try {
                    await runAsync(
                        async () => {
                            return await http.delete('/userOperation/deleteUserOperation', record)
                        },
                        {
                            error: {content: (err) => `修改失败：${err.message}`},
                            loading: {show: true},
                            success: {show: true},
                        }
                    );
                    doSearch();
                } catch (err) {
                    console.log(err);
                }
            }
        });
    }

    const IMainTable = () => {
        const columns = [
            {

                title: '用户名',
                dataIndex: 'username',
            },
            {
                title: '昵称',
                dataIndex: 'nickname',
            },
            {
                title: '创建IP',
                dataIndex: 'crtIp'

            },
            {
                title: '创建时间',
                dataIndex: 'crtTime',

            },
            {
                title: '更新IP',
                dataIndex: 'uptIp'

            },
            {
                title: '更新时间',
                dataIndex: 'uptTime',

            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => {
                    return (<>
                        <a onClick={() => onEdit(record)}>编辑 </a>
                        <a onClick={()=>doDelete(record)}>删除</a>
                    </>)
                }
            }
        ];

        return <Table
            size="small"
            loading={loading}
            dataSource={userOperations.data}
            rowKey={record => record.id}
            pagination={{
                showSizeChanger: true,
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showTotal: (total, range) => (
                    <div>
                        <span>总共 {total} 条数据</span>
                    </div>
                ),
                onChange: handlePageChange,
            }}
            columns={columns}/>;
    }
    return (
        <>
            <FrameLayout>
                <FrameLayout.Header title="运营用户管理"/>
                <FrameLayout.Content>
                    {/*查询条件*/}
                    <div style={{padding: '24px 24px 0', background: '#fff'}}>
                        <Form
                            form={form} onFinish={doSearch}
                            className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={5}>
                                    <Form.Item name="nickname" label="昵称">
                                        <Input size="middle" placeholder="请输入昵称" allowClear/>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name="username" label="用户名">
                                        <Input size="middle" placeholder="请输入用户名" allowClear/>
                                    </Form.Item>
                                </Col>
                                <Col span={5} offset={9} style={{textAlign: 'right'}}>
                                    <Button
                                        onClick={doReset}
                                        style={{margin: '0 12px'}}>重置</Button>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>查询</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="smstable smstable-margin">
                        <div className="smstable-body">
                            {/* 按钮区 */}
                            <div className="smstable-body-toolbar">
                                <Space>
                                    <AddUserOperation onRefresh={doSearch}/>
                                </Space>
                            </div>
                            {/*数据区*/}
                            <IMainTable/>
                        </div>
                    </div>
                </FrameLayout.Content>
                <ModifyUserOperation visible={visibleEdit} setVisible={setVisibleEdit} onCall={doSearch}
                                     userOperation={userOperation}/>
            </FrameLayout>
        </>
    )
}
